.rp_parent
{
    margin-left: 275px;
    text-align: left;
    width: 400px;
    margin-top: 150px;
}
.rp_title{
    width: 100%;
    height: 43px;
    /* UI Properties */
    font-style: normal;
    font-weight:normal;
    font-size:36px;
    line-height:normal;
    font-family: RobotoMedium;
    letter-spacing: 0px;
    color: #121212;
    opacity: 1;
}
.rp_sub_div{
    display: flex;
    height: 19px;
}
.rp_sub_title{
    /* width: 186px; */
    height: 19px;
    /* UI Properties */
    font-size:16px;
    font-family: RobotoRegular;
    text-align: left;
    letter-spacing: 0px;
    line-height: 19px;
    color: #121212;
    opacity: 0.5;
}
.rp_sub_title_create{
    /* width: 111px; */
    height: 19px;
    padding-left: 10px;
    /* UI Properties */
    font-size:16px;
    font-family: RobotoMedium;
    text-align: left;
    letter-spacing: 0px;
    color: #2F3B7D;
    opacity: 1;
    text-decoration: none;
}
.row .rp_labelText{
    margin-top: 20px;
}
.rp_labelText{
    height: 17px;
    /* UI Properties */
    font-size:14px;
    font-family: RobotoMedium;
    text-align: left;
    letter-spacing: 0px;
    color: #121212;
    opacity: 1;
}
.rp_InputText{
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 6px;
    width: 350px;
    height: 35px;
    border: #0099b0 solid 0.5px;
    padding-left: 10px;
    text-align: left;
    font-size: 16px;
    font-family: SourceSansProRegular;
}
.lg_password_eye{
    filter: invert(42%) sepia(64%) saturate(4246%) hue-rotate(162deg) brightness(96%) contrast(103%);
    margin-left: -8%;
}

.rp_submit_bg{
    text-align: center;
    margin-top: 50px;
}
.rp_submit {
    background: #2F3B7D 0% 0% no-repeat padding-box;
    border-radius: 6px;
    width: 180px;
    height: 40px;
    opacity: 1;
    font-size:18px;
    font-family: RobotoMedium;
    text-align: center;
    letter-spacing: 0px;
    line-height: 22px;
    color: #FFFFFF;
}

