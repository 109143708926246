.c_row{
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100%; 
    padding-left: 20px;
    padding-bottom: 20px;
    padding-top: 20px;
    /* -ms-flex-wrap: wrap;
    flex-wrap: wrap; */
    /* margin-right: -15px; */
    /* margin-left: -15px; */
}
.left_banner{
    width: 435px;
    min-height: 680px;
    /* UI Properties */
    /* background: transparent linear-gradient(150deg, var(--unnamed-color-2f3b7d) 0%, #00757F 100%) 0% 0% no-repeat padding-box; */
    background: transparent linear-gradient(150deg, #2F3B7D 0%, #00757F 100%) 0% 0% no-repeat padding-box;
    box-shadow: 2px 2px 6px #2A407D;
    opacity: 1;
    border-radius: 6px;
}

.right_img{
    padding-left: 100px;
    padding-bottom: 100px;
    padding-right: 10px;
    padding-top: 10px;
    float: right;
    width: auto;
    height: 250px;
    opacity: 1;
}
.title_sec{
    float: left;
    margin-left: 15px;
}
/* .content_center{
    position: relative;
    /* margin: auto;  */
    /* margin-left: 30%; */
  /* justify-content: center;
  align-items: center; */
/* } */ 
.title_banner{
    width: 220px;
    height: 61px;
    /* UI Properties */
    text-align: left;
    font-family : RobotoMedium;
    font-size:50px;
    font-style: normal;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}
.title_banner_sec{
    width: 435;
    height: 61px;
    /* UI Properties */
    text-align: left;
    font-family : RobotoMedium;
    font-size:50px;
    font-style: normal;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}
.para_text{
    width: 374px;
    height: 57px;
    /* UI Properties */
    font-style: normal;
    font-weight:normal;
    font-size:16px;
    line-height:normal;
    font-family: RobotoRegular;
    text-align: left;
    font: 16px;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}
.lg_parent
{
    margin-left: 275px;
    text-align: left;
    width: 400px;
    margin-top: 150px;
}
.lg_title{
    width: 100%;
    height: 43px;
    /* UI Properties */
    font-style: normal;
    font-weight:normal;
    font-size:36px;
    line-height:normal;
    font-family: RobotoMedium;
    letter-spacing: 0px;
    color: #121212;
    opacity: 1;
}
.lg_sub_div{
    display: flex;
}
.lg_sub_title{
    /* width: 186px; */
    height: 19px;
    /* UI Properties */
    font-size:16px;
    font-family: RobotoRegular;
    text-align: left;
    letter-spacing: 0px;
    color: #121212;
    opacity: 0.5;
}
.lg_sub_title_create{
    /* width: 111px; */
    height: 19px;
    padding-left: 10px;
    /* UI Properties */
    font-size:16px;
    font-family: RobotoMedium;
    text-align: left;
    letter-spacing: 0px;
    color: #2F3B7D;
    opacity: 1;
    text-decoration: none;
}
.row .lg_labelText{
    margin-top: 20px;
}
.lg_labelText{
    height: 17px;
    /* UI Properties */
    font-size:14px;
    font-family: RobotoMedium;
    text-align: left;
    letter-spacing: 0px;
    color: #121212;
    opacity: 1;
}
.lg_InputText{
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 6px;
    width: 350px;
    height: 35px;
    border: #0099b0 solid 0.5px;
    padding-left: 10px;
    text-align: left;
    font-size: 16px;
    font-family: SourceSansProRegular;
}
.lg_password_eye{
    filter: invert(42%) sepia(64%) saturate(4246%) hue-rotate(162deg) brightness(96%) contrast(103%);
    margin-left: -8%;
    cursor: pointer;
}
.lg_remember{
    display: flex;
    width: 350px;
    margin-top: 20px;
}
.lg_check{
    font-size:14px;
    font-family: RobotoRegular;
    text-align: left;
    letter-spacing: 0px;
    padding-left: 5px;
    color: #121212;
    opacity: 1;
}
.lg_checkbox{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 5px;
    width: 18px;
    height: 18px;
    border: 1px solid #0099b0 ;
    opacity: 1;
    vertical-align: middle;
}
/* .lg_check > label{
    color: #00757F;
} */
.lg_forgot{
    flex: 1;
     height: 17px;
    /* UI Properties */
    font-size:14px;
    font-family: RobotoMedium;
    text-align: right;
    letter-spacing: 0px;
    color: #2F3B7D;
    opacity: 1;
    text-decoration: none;
    margin: auto;
}
.lg_submit_bg{
    text-align: center;
    margin-top: 20px;
}
.lg_submit {
    background: #2F3B7D 0% 0% no-repeat padding-box;
    border-radius: 6px;
    width: 180px;
    height: 40px;
    opacity: 1;
    font-size:18px;
    font-family: RobotoMedium;
    text-align: center;
    letter-spacing: 0px;
    line-height: 22px;
    color: #FFFFFF;
}
.lg_submit > a{
    text-decoration: none;
    font-size:18px;
    font-family: RobotoMedium;
    text-align: center;
    letter-spacing: 0px;
    line-height: 22px;
    color: #FFFFFF;
}
