.ts_parent
{
    margin-left: 50px;
    text-align: left;
    width: 90%;
    margin-top: 20px;
}
.ts_parent_div
{
   display: flex;
    height: 43px;
}
.ts_parent_div > a
{
   text-decoration: none;
}
.ts_parent_div > a > img
{
   width: 43px;
}
.ts_title{
    width: 100%;
    height: 43px;
    /* UI Properties */
    margin-left: 20px;
    font-style: normal;
    font-weight:normal;
    font-size:36px;
    line-height:normal;
    font-family: RobotoMedium;
    letter-spacing: 0px;
    color: #121212;
    opacity: 1;
    text-align: left;
}
.ts_sub_div{
    margin-top: 20px;
    width: 100%;
}
.ts_sub_div > p{
    width: auto;
    font-size:14px;
    font-family: RobotoRegular;
    text-align: left;
    letter-spacing: 0px;
    line-height: 17px;
    color: #121212;
    opacity: 1;
}
.ts_submit_bg{
    text-align: center;
    margin-top: 20px;
}
.ts_submit {
    background: #2F3B7D 0% 0% no-repeat padding-box;
    border-radius: 6px;
    width: 180px;
    height: 40px;
    opacity: 1;
    font-size:18px;
    font-family: RobotoMedium;
    text-align: center;
    letter-spacing: 0px;
    line-height: 22px;
    color: #FFFFFF;
}
