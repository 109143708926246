.fixed-header-hook
{
  width: 100%;
  height: 62px;
  background-color: white;
  opacity: 1;
}
.head_content
{
    padding-left: 30px;
    padding-right: 30px;
}
.header-flex-box_hook {
  margin: auto;
}
.head_parent_br_hook {
  border-bottom: 2px solid red;
}
.head_parent_br_right_hook {
  border-bottom: 2px solid red;
}
.topnav {
  /* overflow: hidden; */
  background-color: white;
}

.topnav a {
    /* width: 16%; */
  width: 20%;
  float: left;
  display: block;
  color: #262626;
  text-align: left;
  padding: 20px;
  font-family: SourceSansProRegular;
  font-weight: 600;
  text-decoration: none;
  font-size: 14px;
  line-height: 20px;
  opacity: 1;  
  letter-spacing: 0px;
}

.topnav_a{
    /* width: 30% !important; */
}

.active {
  color: #0099B0 !important;
}

.topnav .icon {
  display: none;
}

.right_menus
{
    float: right;
  display: flex;
}
.right_menus img
{
    margin-left: 20px;
    margin-right: 20px;
}
.img_bell{
    margin: 20px;
    width: 20px;
    height: 21px;
}
.img_menu_grid{

    width: 26px;
    height: 26px;
}
.img_user_profile{
    border: 1px solid #0099B0;
    border-radius: 50%;
    width: 50px;
    height: 50px;
}

.dropdown {
  /* float: left;
  overflow: hidden; */
    margin: auto;
}

.dropdown .dropbtn {
  font-size: 17px;    
  border: none;
  outline: none;
  color: white;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
}
.dropdown-height{
  overflow-y: scroll;
  width: 216px;
  height: 300px;
}
.dropdown-contents {
  left :-60px;
  top: 25px;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 180px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-contents label {   
  width: 100%;
  float: none;
  /* background-color: #CE181E; */
  color: #CE181E;
  padding: 8px 16px;
  text-decoration: none;
  display: block;
  text-align: right;
  border-top: #CE181E40 solid 1px;
  font-family: SourceSansProRegular;
  font-weight: bold;
  text-decoration: none;
  font-size: 12px;
  line-height: 17px;
  opacity: 1;  
  letter-spacing: 0px;
}
.dropdown-contents a {   
  width: 100%;
  float: none;
  color: black;
  padding: 10px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
  /* border-bottom: #2626264f solid 1px; */
  
  font-family: SourceSansProRegular;
  font-weight: 600;
  text-decoration: none;
  font-size: 14px;
  line-height: 20px;
  opacity: 1;  
  letter-spacing: 0px;
}
.topnav a:hover{
  /* background-color: #0099B0; */
  color:  #0099B0;
}

.dropdown-contents a:hover {
  /* background-color: #ddd; */
  color: #0099B0;
}

.dropdown:hover .dropdown-contents {
  display: block;
}
.dropdown-contents {
  display: none;
}

@media screen and (max-width: 600px) {
  .topnav a:not(:first-child), .dropdown .dropbtn {
    display: none;
  }
  .topnav a.icon {
    float: right;
    display: block;
  }
}

@media screen and (max-width: 600px) {
  .topnav.responsive {position: relative;}
  .topnav.responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
  }
  .topnav.responsive a {
    float: none;
    display: block;
    text-align: left;
  }
  .topnav.responsive .dropdown {float: none;}
  .topnav.responsive .dropdown-contents {position: relative;}
  .topnav.responsive .dropdown .dropbtn {
    display: block;
    width: 100%;
    text-align: left;
  }
}
.hook_logo{
    width: 62px;
    height: 60px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}