.act_content{
    margin-left: 150px;
    margin-top: 70px;
    width: 650px;
}
.act_title{
    width: 100%;
    height: 43px;
    /* UI Properties */
    font-style: normal;
    font-weight:normal;
    font-size:36px;
    font-family: RobotoMedium;
    text-align: left;
    letter-spacing: 0px;
    color: #121212;
    opacity: 1;
}
.act_sub_title{
    /* width: 186px; */
    height: 19px;
    /* UI Properties */
    font-size:16px;
    font-family: RobotoRegular;
    text-align: left;
    letter-spacing: 0px;
    color: #121212;
    opacity: 0.5;
}
.act_sub_title_create{
    /* width: 111px; */
    height: 19px;
    padding-left: 10px;
    /* UI Properties */
    font-size:16px;
    font-family: RobotoMedium;
    text-align: left;
    letter-spacing: 0px;
    color: #2F3B7D;
    opacity: 1;
    text-decoration: none;
}
.row_top{
    margin-top: 30px;
}
.row_sub_top{
    margin-top: 20px;
}
.act_labelText{
    height: 17px;
    /* UI Properties */
    font-size:14px;
    font-family: RobotoMedium;
    text-align: left;
    letter-spacing: 0px;
    line-height: 17px;
    color: #121212;
    opacity: 1;
    display: block;
    margin-bottom: 5px;
}
.act_InputText{
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 6px;
    width: 100%;
    height: 35px;
    border: #0099b0 solid 0.5px;
    padding-left: 10px;
    text-align: left;
    font-size: 14px;
    line-height: 17px;
    opacity: 1;
    font-family: RobotoRegular;
}
.act_InputText_short{
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 6px;
    width: 25%;
    height: 35px;
    border: #0099b0 solid 0.5px;
    padding-left: 10px;
    text-align: left;
    font-size: 14px;
    line-height: 17px;
    opacity: 1;
    font-family: RobotoRegular;
}
.act_InputText_Long{
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 6px;
    width: 75%;
    height: 35px;
    border: #0099b0 solid 0.5px;
    padding-left: 10px;
    text-align: left;
    font-size: 14px;
    line-height: 17px;
    opacity: 1;
    font-family: RobotoRegular;
}
.act_sub_div{
    display: flex;
    margin-top: 15px;
    height: 19px;
}
.act_checkbox{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 5px;
    width: 18px;
    height: 18px;
    border: 1px solid #0099b0 ;
    opacity: 1;
    vertical-align: middle;
    margin-right: 5px;
}
.lg_password_eye{
    filter: invert(42%) sepia(64%) saturate(4246%) hue-rotate(162deg) brightness(96%) contrast(103%);
    margin-left: -8%;
}