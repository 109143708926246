.bodycontent {
  padding: 40px;
  min-height: 568px;
}
.script .topic_title {
  /* width: 54px; */
  height: 28px;
  text-align: left;
  letter-spacing: 0.2px;
  line-height: 24px;
  color: #262626;
  opacity: 1;
  font-size: 20px;
  font-family: SourceSansProBold;
}
.script .table_parent_div {
  margin-top: 20px;
}
.script .table_serach {
  display: flex;
}
.script .table_serach input {
  width: 300px;
  height: 36px;
  border: 1px solid #04113420;
  border-radius: 6px;
  font-family: SourceSansProRegular;
  text-align: left;
  font-size: 14px;
  line-height: 20px;
  opacity: 1;
  letter-spacing: 0px;
  padding-left: 10px;
  color: #262626;
}
.script .table_serach_img {
  margin-left: -4%;
  width: 20px;
  height: 20px;
  margin-top: auto;
  margin-bottom: auto;
  color: #26262640;
}
.script #script > thead > tr > th {
  /* width: 300px; */
  height: 20px;
  font-family: SourceSansProBold;
  text-align: left;
  font-size: 14px;
  line-height: 20px;
  opacity: 1;
  letter-spacing: 0px;
  color: #26262661;
}
.script #script > thead > tr > th > span {
  width: 12px;
  height: 15px;
  opacity: 1;
  color: #26262661;
  margin-right: 10px;
  cursor: pointer;
}
/* .script  #script > thead >tr >th > div{
    background-color: white;
} */
/* #script > thead >tr >th:first-child
{
    width: 93px;
}
.th_str_wd{
    width: 130px;
}
.th_num_wd{
    width: 70px;
} */

.script #script > tbody > tr > td {
  /* width: 300px; */
  height: 20px;
  font-family: SourceSansProRegular;
  font-weight: 600;
  text-align: left;
  font-size: 14px;
  line-height: 20px;
  opacity: 1;
  letter-spacing: 0px;
  color: #262626;
}
.script .td_rating_start {
  width: 12px;
  height: 12px;
  color: #ffd120;
  /* linear-gradient(161deg, #FFD120 0%, #806910 100%) 0% 0% no-repeat padding-box; */
  opacity: 1;
  margin-right: 5px;
  margin-left: 5px;
}
.script .td_div_action_td {
  background-color: white !important;
}
/* .script  .td_div_action{
    position: relative;
    display: flex;
    height: 29px;
}
.script .td_div_action a
{
    width: 24px;
    height: 24px;
    margin-left: auto;
    margin-right: auto;
}
.script .td_div_action_a
{
    display: none;
}.script  .td_div_action:hover .td_div_action_td
{
    background-color: rgb(201, 43, 43) !important;
}
.script .td_div_action:hover .td_div_action_a 
{
    display: block;
} */
.script .ipage {
  margin-top: auto;
  margin-bottom: auto;
  letter-spacing: 0px;
  font-family: SourceSansProRegular;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  color: #041134;
  opacity: 1;
}
.script .pagenumber {
  margin-top: auto;
  margin-bottom: auto;
  letter-spacing: 0px;
  font-family: SourceSansProRegular;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  color: #041134;
  opacity: 1;
}
.script .select_drop {
  width: 44px;
  height: 30px;
  background: #0099b0 0% 0% no-repeat padding-box;
  border: 1px solid #0099b0;
  border-radius: 3px;
  opacity: 1;
  margin-top: auto;
  margin-bottom: auto;
  color: white;
}
.script .prev_button {
  width: 30px;
  height: 30px;
  background: #0099b0 0% 0% no-repeat padding-box;
  border: 1px solid #0099b0;
  border-radius: 3px;
  opacity: 1;
}
.script .prev_button > span {
  width: 10px;
  height: 16px;
  color: white;
  opacity: 1;
  margin-left: -5px;
  margin-right: 7px;
  margin-top: 1px;
  margin-bottom: 4px;
}

.script .add_new {
  width: 114px;
  height: 36px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #99d6df;
  border-radius: 6px;
  opacity: 1;
}

.script .add_new img {
  width: 24px;
  height: 24px;
  opacity: 1;
}

.script .add_new a {
  /* width: 56px;
height: 20px; */
  text-align: left;
  line-height: 20px;
  font-family: SourceSansProRegular;
  font-size: 14px;
  letter-spacing: 0px;
  color: #0099b0;
  opacity: 1;
  margin-left: 8px;
  text-decoration: none;
}

.back_button {
  /* width: 71px; */
  width: 100%;
  height: 17px;
  /* UI Properties */
  text-align: left;
  line-height: 24px;
  font-family: SourceSansProRegular;
  font-size: 12px;
  letter-spacing: 0.12px;
  color: #ce181e;
  opacity: 1;
  cursor: pointer;
}
.back_button .material-icons {
  font-size: 16px !important;
  vertical-align: middle !important;
}
.title_tag {
  /* width: 137px; */
  height: 28px;
  text-align: left;
  line-height: 24px;
  font-family: SourceSansProBold;
  font-size: 20px;
  letter-spacing: 0.2px;
  color: #262626;
  opacity: 1;
  margin-top: 19px;
}

.cld_Textarea::placeholder {
  /* width: 35px;
height: 20px; */
  /* UI Properties */
  text-align: left;
  line-height: 20px;
  font-family: SourceSansProRegular;
  font-size: 14px;
  letter-spacing: 0px;
  color: #262626;
  opacity: 0.5;
}

.save_button {
  width: 87px;
  height: 36px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #99d6df;
  border-radius: 6px;
  opacity: 1;
}

.save_buttons {
  opacity: 1;
  font-size: 19px;
  vertical-align: text-bottom;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: -30px;
  color: #99d6df;
  z-index: 1000;
}

.save_button a {
  /* width: 56px;
height: 20px; */
  text-align: left;
  line-height: 20px;
  font-family: SourceSansProRegular;
  font-size: 14px;
  letter-spacing: 0px;
  color: #0099b0;
  opacity: 1;
  margin-left: 8px;
  text-decoration: none;
}
.error {
  color: #0099b0;
}
.back_buttonss {
  width: 119px;
  height: 36px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #99d6df;
  border-radius: 6px;
  opacity: 1;
}
.back_buttonss a {
  text-align: left;
  letter-spacing: 0px;
  line-height: 20px;
  font-family: SourceSansProRegular;
  font-size: 14px;
  font-weight: 600;
  color: #0099b0;
  opacity: 1;
  text-decoration: none;
}
.back_de .material-icons {
  font-size: 20px;
  vertical-align: middle;
  color: #0099b0;
}
.back_de {
  width: 97px;
  height: 36px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #99d6df;
  border-radius: 6px;
  opacity: 1;
}

.back_de a {
  text-align: left;
  letter-spacing: 0px;
  line-height: 20px;
  font-family: SourceSansProRegular;
  font-size: 14px;
  font-weight: 600;
  color: #0099b0;
  opacity: 1;
  text-decoration: none;
}
.MainContent {
  width: 100%;
}
.MainContent .Itemss {
  width: 180px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* UI Properties */
  /* background: #0099B0 0% 0% no-repeat padding-box; */
  border-radius: 18px;
  opacity: 1;
}
.MainContent .Itemss a {
  text-align: left;
  line-height: 22px;
  font-family: SourceSansProRegular;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  letter-spacing: 0px;
  color: #262626;
  opacity: 0.5;
}

.MainContent .Items_oo {
  width: 184px;
  height: 34px;
  background: #0099b0 0% 0% no-repeat padding-box;
  border-radius: 18px;
  opacity: 1;
}
.MainContent .Items_oo a {
  text-align: left;
  line-height: 22px;
  font-family: SourceSansProRegular;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  display: flex;
  justify-content: center;
  margin-top: 5px;
  text-decoration: none;
}

.MainContent .Items {
  width: 180px;
  height: 34px;
  /* UI Properties */
  background: #0099b0 0% 0% no-repeat padding-box;
  border-radius: 18px;
  opacity: 1;
}
.MainContent .Items a {
  text-align: left;
  line-height: 22px;
  font-family: SourceSansProRegular;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  display: flex;
  justify-content: center;
  margin-top: 5px;
  text-decoration: none;
}
.MainContent #myTabs {
  border: 2px solid #e4e5e5;
  border-radius: 20px;
}
.bib {
  list-style-type: none;
}

.bib li .material-icons {
  font-size: 11px;
  vertical-align: middle;
  color: #0099b0;
  margin-right: 12px;
}
.viedoImg {
  width: 263px;
  height: 150px;
  opacity: 1;
}
.viedoPara {
  text-align: left;
  line-height: 20px;
  font-family: SourceSansProRegular;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0px;

  color: #262626;
  opacity: 1;
}
#inputSearch {
  width: 300px;
  height: 36px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #041134;
  border-radius: 6px;
  opacity: 0.3;
}
#inputSearch::placeholder {
  line-height: 20px;
  font-family: SourceSansProRegular;
  font-size: 14px;
  letter-spacing: 0px;
  color: #262626 !important;
  opacity: 0.7;
  padding-left: 20px;
}
.input-group .btn {
  position: relative;
  z-index: 2;
  right: 41px !important;
}
.input-group .btn .material-icons {
  font-size: 20px;
  color: #262626 !important;
  opacity: 0.7;
  border: none;
  vertical-align: sub;
}
.title_faq {
  text-align: left;
  line-height: 20px;
  font-family: SourceSansProBold;
  font-size: 14px;
  letter-spacing: 0px;
  color: #262626;
  opacity: 0.5;
}
.avartar_img {
  width: 50px;
  height: 50px;
  opacity: 1;
  border: 2px solid #0099b0;
  border-radius: 50%;
}

.clbutton {
  padding: 5px 10px;
  border: none;
  background-color: white;
  cursor: pointer;
  margin-right: 10px;
  text-align: left;
  font-size: var(--fontSizeFourteen);
  color: red;
}

.filter-option {
  margin-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: calc(var(--bs-gutter-x) * 0.5);
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 1;
  line-height: 24px;
  display: flex;
  flex-wrap: nowrap;
  color: black;
}
.filter-option > input {
  margin: 5px;
}
.div1 {
  display: flex;
  flex-wrap: wrap;
  margin-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: calc(var(--bs-gutter-x) * 0.5);
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 1;
}
.div1 > * {
  margin-top: var(--bs-gutter-y);
  max-width: 100%;
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  padding-right: calc(var(--bs-gutter-x) * 0.5);
}
.src_filter-popup {
  position: absolute;
  background: rgba(253, 253, 253, 10);
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: -6px 7px 6px 1px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 99;
  opacity: 10;
}
.rating {
  color: #e7e700;
}
#paperil ul {
  box-shadow: none !important;
  display: flex !important;
  justify-content: flex-start !important;
}
.allclear {
  color: rgb(190 0 0);
  /* font-family: var(--fontfamily2);
    font-size: var(--fontSizeFourteen); */
  font-weight: 10px;
  letter-spacing: 0;
  opacity: 1;
  position: relative;
  text-align: left;
  text-decoration: none;
  top: 3px;
}
.apbutton {
  background-color: rgb(160, 27, 3);
  color: white;
  padding: 5px 10px;
  margin-right: 5px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  overflow: hidden;
  text-align: center;
  width: 100px;
  transform: translateX(0px);
}
.apbutton:hover {
  width: 120px;
  background-color: #020e30;
  text-align: left;
  transition: 0.8s;
}
.apbutton:hover::after {
  content: "⟶";
  position: absolute;
  left: 100px;
}
