.bodycontent{
    padding: 40px;
    min-height: 568px;
}
.cld_div{
    display: flex;
}
.cld_head_submit {
    background: #2F3B7D 0% 0% no-repeat padding-box;
    border-radius: 6px;
    margin-left: 80%;
    width: 100px;
    height: 35px;
    opacity: 1;
    font-size:18px;
    font-family: RobotoMedium;
    text-align: center;
    letter-spacing: 0px;
    line-height: 22px;
    color: #FFFFFF;
}
.topic_title{
    /* width: 54px; */
    height: 28px;
    text-align: left;
    letter-spacing: 0.2px;
    line-height: 24px;
    color: #262626;
    opacity: 1;
    font-size: 20px;
    font-family: SourceSansProBold;
}

.cld_table_serach{
    display: flex;
}
.cld_table_serach input{
    width: 300px;
    height: 36px;
    border: 1px solid #04113420;
    border-radius: 6px; 
    font-family: SourceSansProRegular;
    text-align: left;
    font-size: 14px;
    line-height: 20px;
    opacity: 1;  
    letter-spacing: 0px;
    padding-left: 10px;
    color: #262626;
}
.cld_table_serach_img{
    margin-left: -4%;
    width: 20px;
    height: 20px;
    margin-top: auto;
    margin-bottom: auto;
    color: #26262640;
}

.cld_content{
    /* margin-left: 150px;
    margin-top: 30px;
    width: 650px; */
}
.cld_labelText{
    height: 17px;
    /* UI Properties */
    font-size:14px;
    font-family: RobotoMedium;
    text-align: left;
    letter-spacing: 0px;
    line-height: 17px;
    color: #121212;
    opacity: 1;
    display: block;
    margin-bottom: 5px;
}
.cld_InputText{
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 6px;
    width: 100%;
    height: 35px;
    border: #0099b0 solid 0.5px;
    padding-left: 10px;
    text-align: left;
    font-size: 14px;
    line-height: 17px;
    opacity: 1;
    font-family: RobotoRegular;
}
.cld_Textarea {
  background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 6px;
    width: 100%;
    height: 100px;
    border: #0099b0 solid 0.5px;
    padding-left: 10px;
    text-align: left;
    font-size: 14px;
    line-height: 17px;
    opacity: 1;
    font-family: RobotoRegular;
}
.inp_grp{

    margin-top: 10px;
}
.cld_submit_bg{
    text-align: right;
    margin-top: 20px;
}
/* .cld_submit {
    background: #2F3B7D 0% 0% no-repeat padding-box;
    border-radius: 6px;
    width: 180px;
    height: 40px;
    opacity: 1;
    font-size:18px;
    font-family: RobotoMedium;
    text-align: center;
    letter-spacing: 0px;
    line-height: 22px;
    color: #FFFFFF;
} */
.cld_td_action{
     
    margin-left: 5px;
    margin-right: 5px;
    color: #121212;
    opacity: .5;
}
.cld_td_action:hover{
    color: #0099b0;
    opacity: 1;
}
.add_new{
    width: 114px;
height: 36px;
background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px #00000029;
border: 1px solid #99D6DF;
border-radius: 6px;
opacity: 1;
}
.add_new img {

    width: 24px;
height: 24px;
opacity: 1;
}

.add_new span{
    /* width: 56px;
height: 20px; */
text-align: left;
line-height: 20px;
font-family: SourceSansProRegular;
font-size: 14px;
letter-spacing: 0px;
color: #0099B0;
opacity: 1;
margin-left: 8px;
text-decoration: none;
}
.save_button{

    /* width: 87px;
height: 36px;
background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px #00000029;
border: 1px solid #99D6DF;
border-radius: 6px;
opacity: 1; */

  width: 180px;
    height: 40px;
    opacity: 1;
    font-size:18px;
    font-family: RobotoMedium;
    text-align: right;
    letter-spacing: 0px;
    line-height: 22px;
    color: #FFFFFF;
}

.save_button .material-icons{

    opacity: 1;
    font-size: 19px;
    vertical-align: text-bottom;
    color: #99D6DF;
}


 .save_button span{
    /* width: 56px;
height: 20px; */
text-align: left;
line-height: 20px;
font-family: SourceSansProRegular;
font-size: 14px;
letter-spacing: 0px;
color: #0099B0;
opacity: 1;
margin-left: 8px;
text-decoration: none;
}
.ReviewLabel { 

    /* width: 83px; */
height: 20px;
/* UI Properties */
text-align: left;
line-height: 20px;
font-family: SourceSansProRegular;
font-size: 14px;
letter-spacing: 0px;
color: #262626;
opacity: 1;
font-weight: 600;

}
.ReviewLabels{

    /* width: 75px; */
height: 20px;
/* UI Properties */

text-align: right;
line-height: 20px;
font-family: SourceSansProBold;
font-size: 14px;
letter-spacing: 0px;
color: #262626;
opacity: 0.5;
display: flex;
}
.back_buttons{
    width: 82px;
height: 36px;
/* UI Properties */
background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px #00000029;
border: 1px solid #99D6DF;
border-radius: 6px;
opacity: 1;
}
.back_buttons .material-icons {
    font-size: 16px !important;
    vertical-align: middle !important;
    color: #99D6DF !important;
}
.back_buttons a{

    width: 24px;
height: 20px;

text-align: left;
font-weight: 600;
line-height: 20px;
font-family: SourceSansProRegular;
font-size: 14px;
letter-spacing: 0px;
color: #0099B0;
opacity: 1;
text-decoration: none;
}