.fg_parent
{
    margin-left: 50px;
    text-align: left;
    width: 733px;
    margin-top: 150px;
}
.fg_parent_div
{
   display: flex;
    height: 43px;
}
.fg_parent_div > a
{
   text-decoration: none;
}
.fg_parent_div > a > img
{
   width: 43px;
   
}
.fg_title{
    width: 100%;
    height: 43px;
    /* UI Properties */
    margin-left: 20px;
    font-style: normal;
    font-weight:normal;
    font-size:36px;
    line-height:normal;
    font-family: RobotoMedium;
    letter-spacing: 0px;
    color: #121212;
    opacity: 1;
    text-align: left;
}
.fg_label_div{
    margin-top: 50px;
}
.fg_label_email{
    height: 19px;
    /* UI Properties */
    font-size:16px;
    font-family: RobotoMedium;
    text-align: left;
    letter-spacing: 0px;
    color: #313131;
    opacity: 1;
}
.fg_label_normal{
    width: 100%;
    /* UI Properties */
    font-size:16px;
    font-family: RobotoRegular;
    text-align: left;
    letter-spacing: 0px;
    line-height: 19px;
    color: #313131;
    opacity: 1;
}
.fg_label_normal > a{

    font-size:16px;
    font-family: RobotoMedium;
    text-align: left;
    letter-spacing: 0px;
    color: #2F3B7D;
    opacity: 1;
    text-decoration: none;
}
.fg_div_labelText{
    margin-top: 30px;
}
.fg_labelText{
    width: 100%;
    height: 17px;
    /* UI Properties */
    font-size:14px;
    font-family: RobotoMedium;
    text-align: left;
    letter-spacing: 0px;
    color: #121212;
    opacity: 1;
}
.fg_InputText{
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 6px;
    width: 350px;
    height: 35px;
    border: #0099b0 solid 0.5px;
    padding-left: 10px;
    text-align: left;
    font-size: 16px;
    font-family: SourceSansProRegular;
}
.fg_sub_div{
    display: flex;
    margin-top: 5px;
    width: 450px;
}
.fg_sub_div_img{
    width: 20px;
    height: 24px;
    margin: auto;
    color: #2F3B7D;
}
.fg_sub_div_img > img{
    width: 20px;
    height: 24px;
    margin: auto;
    color: #2F3B7D;
}
.fg_sub_div > label{
    margin: auto;
    height: 24px;
    font-size:20px;
    font-family: RobotoMedium;
    text-align: left;
    letter-spacing: 0px;
    line-height: 24px;
    color: #313131;
    opacity: 1;
}
.fg_sub_title{
    /* width: 186px; */
    height: 17px;
    /* UI Properties */
    font-size:14px;
    font-family: RobotoRegular;
    text-align: left;
    letter-spacing: 0px;
    color: #121212;
    opacity: 0.5;
}
.fg_sub_title_create{
    /* width: 111px; */
    height: 17px;
    padding-left: 10px;
    /* UI Properties */
    font-size:14px;
    font-family: RobotoMedium;
    text-align: left;
    letter-spacing: 0px;
    color: #2F3B7D;
    opacity: 1;
    text-decoration: none;
}
.fg_submit_bg{
    text-align: center;
    margin-top: 20px;
}
.fg_submit {
    background: #2F3B7D 0% 0% no-repeat padding-box;
    border-radius: 6px;
    height: 40px;
    opacity: 1;
    font-size:18px;
    font-family: RobotoMedium;
    text-align: center;
    letter-spacing: 0px;
    line-height: 22px;
    color: #FFFFFF;
}

