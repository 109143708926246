.bodycontent{
    padding: 40px;
    min-height: 568px;
}
.topic_title{
    /* width: 54px; */
    height: 28px;
    text-align: left;
    letter-spacing: 0.2px;
    line-height: 24px;
    color: #262626;
    opacity: 1;
    font-size: 20px;
    font-family: SourceSansProBold;
}
.table_parent_div{
    margin-top: 20px;
}
.table_serach{
    display: flex;
}
.table_serach input{
    width: 300px;
    height: 36px;
    border: 1px solid #04113420;
    border-radius: 6px; 
    font-family: SourceSansProRegular;
    text-align: left;
    font-size: 14px;
    line-height: 20px;
    opacity: 1;  
    letter-spacing: 0px;
    padding-left: 10px;
    color: #262626;
}
.table_serach_img{
    margin-left: -2%;
    width: 20px;
    height: 20px;
    margin-top: auto;
    margin-bottom: auto;
    color: #26262640;
}
#script > thead >tr >th{
    /* width: 300px; */
    height: 20px;
    font-family: SourceSansProBold;
    text-align: left;
    font-size: 14px;
    line-height: 20px;
    opacity: 1;  
    letter-spacing: 0px;
    color: #26262661;
}
#script > thead >tr >th > span{
    width: 12px;
    height: 15px;
    opacity: 1;  
    color: #26262661;
    margin-right: 10px;
    cursor: pointer;
}
#script > thead >tr >th > input{
    color: red;
}
/* #script > thead >tr >th:first-child
{
    width: 93px;
}
.th_str_wd{
    width: 130px;
}
.th_num_wd{
    width: 70px;
} */

#script > tbody >tr >td{
    /* width: 300px; */
    height: 20px;
    font-family: SourceSansProRegular;
    font-weight: 600;
    text-align: left;
    font-size: 14px;
    line-height: 20px;
    opacity: 1;  
    letter-spacing: 0px;
    color: #262626;
}
.td_rating_start{
    width: 12px;
    height: 12px;
   color:  #FFD120;
   /* linear-gradient(161deg, #FFD120 0%, #806910 100%) 0% 0% no-repeat padding-box; */
    opacity: 1;
    margin-right: 5px;
    margin-left: 5px;
}
.td_div_action_td{
    background-color: white !important;
}
.td_div_action{
    /* position: relative;
    display: flex;
    height: 29px; */
}
.td_div_action_div{
    position: relative;
    display: flex;
    height: 29px;
}
.td_div_action a
{
    width: 24px;
    height: 24px;
    margin-left: auto;
    margin-right: auto;
}
.td_div_action_a
{
    display: none;
}.td_div_action:hover .td_div_action_td
{
    background-color: rgb(201, 43, 43) !important;
}
.td_div_action:hover .td_div_action_a 
{
    display: block;
}
.ipage {
    
    margin-top: auto;
    margin-bottom: auto;
    letter-spacing: 0px;
    font-family: SourceSansProRegular;
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    color: #041134;
    opacity: 1;
}
.pagenumber{
     margin-top: auto;
    margin-bottom: auto;
    letter-spacing: 0px;
    font-family: SourceSansProRegular;
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    color: #041134;
    opacity: 1;
}
.select_drop{
    width: 44px;
    height: 30px;
    background: #0099B0 0% 0% no-repeat padding-box;
    border: 1px solid #0099B0;
    border-radius: 3px;
    opacity: 1;
    margin-top: auto;
    margin-bottom: auto;
    color: white;
}
.prev_button
{
    width: 30px;
    height: 30px;
    background: #0099B0 0% 0% no-repeat padding-box;
    border: 1px solid #0099B0;
    border-radius: 3px;
    opacity: 1;
}
.prev_button > span
{
    width: 10px;
    height: 16px;
    color: white;
    opacity: 1;
    margin-left: -5px;
    margin-right: 7px;
    margin-top: 1px;
    margin-bottom: 4px;
}