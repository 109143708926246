
#footer{
width: 100%;
    height: fit-content;
/* UI Properties */
background: #07182B 0% 0% no-repeat padding-box;
box-shadow: -6px 0px 6px #00000029;
opacity: 1;
}
.footer_head_title{

    text-align: left;
    font-family: SourceSansProBold;
    font-size: 26px;
    letter-spacing: 0.94px;
    color: #FFFFFF;
    opacity: 1;

}
.footer_sub_heading{

    text-align: left;
    font-family: SourceSansProRegular;
    font-weight:normal ;
    font-size: 18px;
    letter-spacing: 0.41px;
    color: #FFFFFF;
    opacity: 1;
}
.footer_subscribe{

    background: #0099B0 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px #00000029;
border: 2px solid #0099B0;
border-radius: 8px;
opacity: 1;
text-align: left;
font-family: SourceSansProRegular;
font-weight:600 ;
font-size: 16px;
letter-spacing: 0px;
color: #FFFFFF;
opacity: 1;
}

#footer_one{
    margin-top: 30px;
}
#footer_ones{
    margin-top: 30px;
    margin-bottom: 0px;
}
.footer_two{

    margin-top: 44px;
}
#footerLogo{

    width: 150px;
height: 146px;
/* UI Properties */
opacity: 1;
margin-left: 35%;
margin-top: 30px;

}

#footer_three{

    margin-top: 60px;
   
}

#ulTag{

    list-style-type: none;
    padding: 10px;
}
#ulTag li {

    text-align: left;
    font-family: SourceSansProRegular;
    font-weight:normal ;
    font-size: 14px;
    letter-spacing: 0.42px;
    color: #FFFFFF;
    opacity: 1;
    margin: 20px;

}

#ulTag li a{

    text-decoration: none;
    font-family: SourceSansProRegular;
    font-weight:normal ;
    font-size: 14px;
        letter-spacing: 0.42px;
        color: #FFFFFF;
}

#footer_four{

    border: 1px solid #cfc3c3;
    width: 94%;
    margin-top: 30px;
    position: relative;
    left: 2%;
}
    

.footer_five{

    border: 1px solid #cfc3c3;
    width: 94%;
    margin-top: 30px;
    position: relative;
    left: 2%;
}
.FooterText{

    text-align: left;
letter-spacing: 0.42px;
font-family: SourceSansProRegular;
font-weight:normal ;
font-size: 14px;
color: #FFFFFF;
opacity: 1;
text-decoration: none;

}

.FooterTexts{
    text-align: left;
    letter-spacing: 0.42px;
    font-family: SourceSansProRegular;
    font-weight:normal ;
    font-size: 14px;
    color: #FFFFFF;
    opacity: 1;
    text-decoration: none;
    position: relative;
    bottom: 10px;
}

#emailText{

    opacity: 1;
    color: #0099B0;
    margin-right: 20px;
    vertical-align: bottom;
    font-size: 20px;
}


.BorderFoot{
  margin-top: 32px;
  border-right: 1px solid #FFFFFF;
}

.BorderFoots{

    margin-top: 32px;
}
.LocationText{

    position: relative;
    top: 20px;
    right: 34%;
}
.socialIocns{

color: white;
font-size: 20px;
opacity: 1;
padding-left: 20px;
}

#copyRight{
    text-align: center;
    font-family: SourceSansProRegular;
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0.42px;
    color: #FFFFFF;
    opacity: 1;
    margin-left: 35%;
}