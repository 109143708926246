body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
	height: 100%;
	min-height: 100%;
}
.form-control {
  font-family: SourceSansProRegular;  
}
.InputText {
  font-family: SourceSansProRegular;  
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
}

@font-face {
  font-family: "SourceSansProBold";   /*Can be any text*/
  src: local("SourceSansProBold"), url("./font/Source_Sans_Pro/SourceSansPro-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "SourceSansProRegular";   /*Can be any text*/
  src: local("SourceSansProRegular"), url("./font/Source_Sans_Pro/SourceSansPro-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "SourceSansProSemiBold";   /*Can be any text*/
  src: local("SourceSansProSemiBold"), url("./font/Source_Sans_Pro/SourceSansPro-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "RobotoRegular";   /*Can be any text*/
  src: local("RobotoRegular"), url("./font/Roboto/Roboto-Regular.ttf") format("truetype");
}
 @font-face {
  font-family: "RobotoMedium";   /*Can be any text*/
   src: local("RobotoMedium"), url("./font/Roboto/Roboto-Medium.ttf") format("truetype"); 
 } 