
#footer_hook{
width: 100%;
height: 178px;
/* UI Properties */
background: #07182B 0% 0% no-repeat padding-box;
box-shadow: -6px 0px 6px #00000029;
opacity: 1;
}

.FooterText{

    text-align: left;
letter-spacing: 0.42px;
font-family: SourceSansProRegular;
font-weight:normal ;
font-size: 14px;
color: #FFFFFF;
opacity: 1;
text-decoration: none;

}

.FooterTexts{
    text-align: left;
    letter-spacing: 0.42px;
    font-family: SourceSansProRegular;
    font-weight:normal ;
    font-size: 14px;
    color: #FFFFFF;
    opacity: 1;
    text-decoration: none;
    position: relative;
    bottom: 10px;
}

#emailText{

    opacity: 1;
    color: #0099B0;
    margin-right: 20px;
    vertical-align: bottom;
    font-size: 20px;
}


.BorderFoot{
  margin-top: 32px;
  border-right: 1px solid #FFFFFF;
  text-align: center;
}

.BorderFoots{

  text-align: center;
  margin-top: 32px;
}
.LocationText{

    position: relative;
    top: 20px;
    right: 34%;
}
.socialIocns{

color: white;
font-size: 20px;
opacity: 1;
padding-left: 20px;
}

#copyRight_hook{
    text-align: center;
    font-family: SourceSansProRegular;
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0.42px;
    color: #FFFFFF;
    opacity: 1;
    margin-left: 35%;
}