.bodycontent{
    padding: 40px;
    min-height: 568px;
}
.prv_div{
    display: flex;
}
.prv_head_submit {
    background: #2F3B7D 0% 0% no-repeat padding-box;
    border-radius: 6px;
    margin-left: 80%;
    width: 100px;
    height: 35px;
    opacity: 1;
    font-size:18px;
    font-family: RobotoMedium;
    text-align: center;
    letter-spacing: 0px;
    line-height: 22px;
    color: #FFFFFF;
}
.topic_title{
    /* width: 54px; */
    height: 28px;
    text-align: left;
    letter-spacing: 0.2px;
    line-height: 24px;
    color: #262626;
    opacity: 1;
    font-size: 20px;
    font-family: SourceSansProBold;
}
.prv_table_serach{
    display: flex;
}
.prv_table_serach input{
    width: 300px;
    height: 36px;
    border: 1px solid #04113420;
    border-radius: 6px; 
    font-family: SourceSansProRegular;
    text-align: left;
    font-size: 14px;
    line-height: 20px;
    opacity: 1;  
    letter-spacing: 0px;
    padding-left: 10px;
    color: #262626;
}
.prv_table_serach_img{
    margin-left: -4%;
    width: 20px;
    height: 20px;
    margin-top: auto;
    margin-bottom: auto;
    color: #26262640;
}


.prv_content{
    margin-left: 150px;
    margin-top: 30px;
    width: 650px;
}
.prv_labelText{
    height: 17px;
    /* UI Properties */
    font-size:14px;
    font-family: RobotoMedium;
    text-align: left;
    letter-spacing: 0px;
    line-height: 17px;
    color: #121212;
    opacity: 1;
    display: block;
    margin-bottom: 5px;
}
.prv_InputText{
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 6px;
    width: 100%;
    height: 35px;
    border: #0099b0 solid 0.5px;
    padding-left: 10px;
    text-align: left;
    font-size: 14px;
    line-height: 17px;
    opacity: 1;
    font-family: RobotoRegular;
}
.prv_Textarea {
  background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 6px;
    width: 100%;
    height: 100px;
    border: #0099b0 solid 0.5px;
    padding-left: 10px;
    text-align: left;
    font-size: 14px;
    line-height: 17px;
    opacity: 1;
    font-family: RobotoRegular;
}
.inp_grp{

    margin-top: 10px;
}
.prv_submit_bg{
    text-align: center;
    margin-top: 20px;
}
.prv_submit {
    background: #2F3B7D 0% 0% no-repeat padding-box;
    border-radius: 6px;
    width: 180px;
    height: 40px;
    opacity: 1;
    font-size:18px;
    font-family: RobotoMedium;
    text-align: center;
    letter-spacing: 0px;
    line-height: 22px;
    color: #FFFFFF;
}