.fixed-header {
  z-index: 9999;
  width: 100%;
  height: 110px;
  background-color: transparent;
  opacity: 1;
  position: absolute;
  top: 0px;
  bottom: 0px;
}

.header-flex-box {
  display: flex;
}
.head_parent {
  padding-top: 30px;
}
.head_parent_br {
  border-bottom: 1px solid white;
  position: absolute;
  top: 90px;
  left: 25px;
}
.head_parent_br_right {
  border-bottom: 1px solid white;
  position: absolute;
  top: 90px;
  right: 25px;
}
.head_parent_right {
  text-align: right;
}
.head_menu,
.nav-link {
  height: 20px;
  padding: 10px;
  /* UI Properties */
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  font-weight: 600;
  font-family: SourceSansProSemiBold;
}
.navbar-nav .nav-item.active, .navbar-nav .nav-item.show-link .nav-link  {
  color: #0099B0 !important;

}
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show-link  {
  color: #0099B0 !important;

}
.nav-link:hover {
  color: #0099b0;
  opacity: 1;
}
.head_logo {
  width: 100px;
  height: 97px;
}
.navbar-brand {
  position: relative !important;
  left: 46% !important;
  top: 18px !important;
}
.navbar-collapse {
  position: absolute !important;
  left: 4% !important;
  bottom: 61% !important;
}
.navbar-nav .nav-item {
  padding-right: 60px !important;
}
.navbar-right {
  position: relative;
  bottom: 16px;
}

.navbar-right .btnStart {
  width: 110px;
  height: 40px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #0099b0;
  border-radius: 8px;
  opacity: 1;
  margin-right: 30px;
}

.navbar-right .btnStart a {
  text-decoration: none;
  text-align: left;

  font-weight: 600;
    font-size: 16px;
    font-family: SourceSansProRegular;
  letter-spacing: 0px;
  color: #0099b0;
  opacity: 1;
}

.navbar-right .btn_end {
  width: 80px;
  height: 40px;
  /* UI Properties */
  background: #0099b0 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 2px solid #0099b0;
  border-radius: 8px;
  opacity: 1;
}

.navbar-right .btn_end a {
  text-decoration: none;
  /* UI Properties */
  text-align: left;
  font-weight: 600;
    font-size: 16px;
    font-family: SourceSansProRegular;
  letter-spacing: 0px;
  color: #ffffff !important;
  opacity: 1;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity and color as needed */
}

.head_bt {
  height: 20px;
  padding: 10px;
  margin-right: 10px;
  /* UI Properties */
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #0099b0;
  background-color: #ffffff;
  opacity: 1;
  font-weight: 500;
  border-radius: 6px;
}
.head_bt:hover {
  height: 20px;
  padding: 10px;
  margin-right: 10px;
  /* UI Properties */
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #ffffff;
  background-color: #0099b0;
  opacity: 1;
  font-weight: 500;
  border-radius: 6px;
}

/* css 
from 
junior */

#id1 {
  border: 1px solid black;
  width: auto;
  height: 100%;
  background-image: url("../../assets/Group\ 78.png");
  background-repeat: no-repeat;
  background-position: center;
  /* background-size: cover; */
}
/* .size
{
    width: 100%;
    height: 100%;
} */

/* .style {
  width: 100%;
  height: 46px;
  text-align: left;
  letter-spacing: 5.12px;
  color: #ce181e;
  text-transform: uppercase;
  font-family:"Source Sans Pro" ;
  font-weight: bold;
  opacity: 1;
  font-size: 32px;
  margin-top: 305px;
  padding: 10px 40px;
}
.sheet {
  width: 100%;
  height: 108px;
  text-align: left;
  font-family:"Source Sans Pro" ;
  font-weight: normal;
  opacity: 1;
  letter-spacing: 0px;
  line-height: 40px;
  color: #ffffff;
  padding: 0px 40px;
  font-size: 20px;
} */
 /* .sec {
    font-size: 21px !important;
    width: 100%;
    padding: 0px;
    margin-top: 20px !important;
  } */
.sec {
  /* width: 1286px;
    height: 51px; */
  /* UI Properties */
  text-align: center;
  text-decoration: underline;
  font-family: SourceSansProBold;
  /* font-weight: bold; */
  opacity: 1;
  font-size: 42px;
  padding: 80px 40px 20px;
  letter-spacing: 6.72px;
  color: #ce181e;
  opacity: 1;
  /* margin-top: 28%; */
  margin-bottom: 3%;
}

#cls {
  width: 97%;
  height: 500px;
  font-family: SourceSansPro-Regular;
  padding: 0px 40px 20px;
  letter-spacing: 2.16px;
  opacity: 1;
  overflow: auto;
}
#cls::-webkit-scrollbar {
  width: 3px; /* Set the width of the scrollbar */
  height: 2px;
}

#cls::-webkit-scrollbar-thumb {
  border: 3px solid #ce181e;
  opacity: 1;
  height: 1px !important;
}
.cls1 {
  width: 100%;
  height: 600px;
}
.select {
  /* width: 1286px;
    height: 690px; */
  /* UI Properties */
  text-align: left;
  font-weight: normal;
  font-size: 18px;
  font-family:SourceSansProRegular;
  letter-spacing: 2.16px;
  color: #ce181e;
  opacity: 1;
  line-height: 35px;
}
.pos_content {
  /* display: flex; */
  width: 100%;
}
.pos_div {
  /* width: 100%;
  height: 100%; */
  padding-left: 15px;
  text-align: left;
}
.pos_content_title {
  text-align: left;
  font-family: SourceSansProBold;
  letter-spacing: 5.12px;
  color: #ce181e;
  text-transform: uppercase;
  opacity: 1;
  font-size: 32px;
}

.pos_content_para {
  text-align: left;
  font-family: SourceSansProRegular;
  letter-spacing: 0px;
  line-height: 40px;
  color: #262626;
  opacity: 1;
  font-size: 20px;
  margin-bottom: 30px;
}
.button_bg {
  /* display: flex; */
  width: 150px;
  height: 40px;
  background: #0099b0 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 2px solid #0099b0;
  border-radius: 8px;
  opacity: 1;
}
.bt_span {
  width: 77px;
  font-size: 16px;
  line-height: 22px;
  color: white;
  font-family: SourceSansProRegular;
  font-weight: 600;
}

.img_arr {
  width: 19px;
  height: 8px;
  margin-top: 11px;
  margin-bottom: 11px;
  margin-left: 20px;
}

#sectionTwo {
  background-color: #fff9e7;
  height: 768px;
}

#sectionThree {
  background-color: white;
  height: 600px;
  padding: 86px 25px;
}
#sectionOne {
  /* width: 1366px; */
  height: 768px;
  /* UI Properties */
  background: transparent url("../../assets/pp.png") 0% 0% no-repeat padding-box;
  /* opacity: 0.4; */
}
#sectionThrees {
  background-color: #effbfe;
  height: 600px;
  padding: 86px 25px;
}

#sectionFour {
  width: 1326px;
  height: 778px;
  /* UI Properties */
  background: transparent url("../../assets/Group 78.png") 0% 0% no-repeat
    padding-box;
  opacity: 1;
  margin-bottom: 100px;
  margin-top: 80px;
}
.c_title {
  text-align: center;
  font-size: 24px;
  font-family: SourceSansProBold;
  font-weight: bold;
  letter-spacing: 0px;
  color: #ce181e;
  opacity: 1;
  margin-top: 30px;
  margin-bottom: 20px;
}
.c_subTitle {
  text-align: center;
  font-size: 48px;
  font-family: SourceSansProBold;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
.p_title {
  text-align: center;
  font-size: 18px;
  font-family: SourceSansProRegular;
  font-weight: normal;
  letter-spacing: 0px;
  color: #ffffffdb;
  opacity: 1;
}
.labelText {
  text-align: left;
  font-size: 18px;
  font-family: SourceSansProRegular;
  font-weight: 600;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  display: block;
  margin-top: 30px;
}

.InputText {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 0.5;
  width: 415px;
  height: 40px;
  border: none;
  padding-left: 10px;
  text-align: left;
  font-size: 16px;
  font-family: SourceSansProRegular;
}

.InputText::placeholder,
.InputTexts::placeholder,
.InputTextarea::placeholder {
  /* padding-left: 10px; */
}

.Inputselect {
  width: 112px;
  height: 40px;
  position: relative;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid #0096ad;
  border-radius: 6px;
  opacity: 1;
}

.InputTexts {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 0.5;
  width: 415px;
  height: 40px;
  border: none;
  position: relative;
  left: 5%;
  padding-left: 10px;
  text-align: left;
  font-size: 16px;
  font-family: SourceSansProRegular;
}

.InputTextarea {
  width: 100%;
  height: 100px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 0.5;
  padding-left: 10px;
  text-align: left;
  font-size: 16px;
  font-family: SourceSansProRegular;
}
.btnGet {
  width: 147px;
  height: 40px;
  color: white;
  /* UI Properties */
  background: #0099b0 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 2px solid #0099b0;
  border-radius: 8px;
  opacity: 1;
}

.btnGet a {
  text-decoration: none;
  text-align: left;

  
  font-size: 16px;
  font-family: SourceSansProRegular;
  font-weight: 600;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

#sectionFive {
  width: 1286px;
  height: 734px;
  /* UI Properties */
  opacity: 1;
  margin-top: 40px;
}

/* ToggleButton.css */

.switches-container {
  width: 400px;
  height: 80px;
  position: relative;
  display: flex;
  padding: 0;
  position: relative;
  background: #0099b05c 0% 0% no-repeat padding-box;
  line-height: 3rem;
  border-radius: 3rem;
  margin-left: auto;
  margin-right: auto;
}

/* input (radio) for toggling. hidden - use labels for clicking on */
.switches-container input {
  visibility: hidden;
  position: absolute;
  top: 0;
}

/* labels for the input (radio) boxes - something to click on */
.switches-container label {
  width: 50%;
  padding: 0px;
  position: relative;
  margin: 0;
  text-align: center;
  cursor: pointer;
  
  font-size: 24px;
  font-family: SourceSansProRegular;
  font-weight: 600;
  letter-spacing: 0px;
  color: #262626;
  opacity: 1;
  top: 20px;
}

/* switch highlighters wrapper (sliding left / right) 
    - need wrapper to enable the even margins around the highlight box
*/
.switch-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50%;
  padding: 0.15rem;
  z-index: 3;
  transition: transform 0.5s cubic-bezier(0.77, 0, 0.175, 1);
  /* transition: transform 1s; */
}

/* switch box highlighter */
.switch {
  border-radius: 3rem;
  background: #0099b0 0% 0% no-repeat padding-box;

  height: 100%;
}

/* switch box labels
    - default setup
    - toggle afterwards based on radio:checked status 
*/
.switch div {
  width: 100%;
  text-align: center;
  opacity: 0;
  display: block;
  color: white;
  transition: opacity 0.2s cubic-bezier(0.77, 0, 0.175, 1) 0.125s;
  will-change: opacity;
  position: absolute;
  top: 19px;
  left: 0;

  font-size: 24px;
  font-family: SourceSansProRegular;
  font-weight: 600;
  letter-spacing: 0px;
}

/* slide the switch box from right to left */
.switches-container input:nth-of-type(1):checked ~ .switch-wrapper {
  transform: translateX(0%);
}

/* slide the switch box from left to right */
.switches-container input:nth-of-type(2):checked ~ .switch-wrapper {
  transform: translateX(100%);
}

/* toggle the switch box labels - first checkbox:checked - show first switch div */
.switches-container
  input:nth-of-type(1):checked
  ~ .switch-wrapper
  .switch
  div:nth-of-type(1) {
  opacity: 1;
}

/* toggle the switch box labels - second checkbox:checked - show second switch div */
.switches-container
  input:nth-of-type(2):checked
  ~ .switch-wrapper
  .switch
  div:nth-of-type(2) {
  opacity: 1;
}

/* price section design  */

.cardText {
  width: 415px;
  height: 624px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 16px;
  opacity: 1;
}

.cardBody {
  width: 385px;
  height: 223px;
  /* UI Properties */
  background: #0099b01f 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 16px;
  position: relative;
  left: 15px;
  top: 15px;
}
.priceTag {
  margin-top: 45px;
  margin-bottom: 80px;
}

.priceTag .Title {
  width: 140px;
  height: 60px;
  /* UI Properties */
  background: #ffbe18 0% 0% no-repeat padding-box;
  border-radius: 50px;
  opacity: 1;
  margin-top: 20px;
  margin-bottom: 10px;
  border: none;
}

.priceTag .Title a {
  text-align: left;

  font-size: 24px;
  font-family: SourceSansProRegular;
  font-weight: 600;
  letter-spacing: 0px;
  color: #ffffff;
  text-decoration: none;
  opacity: 1;
}

.priceTag .subTitle {
  text-align: center;
  font-size: 48px;
  font-family: SourceSansProBold;
  font-weight: bold;
  letter-spacing: 0px;
  color: #262626;
  opacity: 1;
}
.priceTag .sTitle {
  text-align: center;

  font-size: 24px;
  font-family: SourceSansProRegular;
  font-weight: normal;
  letter-spacing: 0px;
  color: #262626;
  opacity: 1;
}
.priceTag .PriceCheckbox {
  list-style-type: none;
  margin-top: 40px;
}
.priceTag .PriceCheckbox li {
  text-align: left;

  font-size: 18px;
  font-family: SourceSansProRegular;
  font-weight: 600;
  letter-spacing: 0px;
  color: #262626;
  opacity: 1;
  padding-bottom: 0px;
  padding-top: 0px;
}

.priceTag .PriceCheckbox li i {
  color: #0099b0;
  opacity: 1;
}

.priceTag .PriceCheckbox li .borderSpan {
  width: 360px;
  height: 0px;
  border: 1px solid #ce181e;
  opacity: 0.2;
  margin-top: 10px;
}

.priceTag .btnprice {
  margin-top: 40px;
  width: 385px;
  height: 60px;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 2px solid #0099b0;
  border-radius: 30px;
  opacity: 1;
}

.priceTag .btnprice a {
  text-align: left;
  font-size: 24px;
  font-family: SourceSansProRegular;
  font-weight: 600;
  letter-spacing: 0px;
  color: #0099b0;
  opacity: 1;
  text-decoration: none;
}
.priceTag .btnprices {
  margin-top: 40px;
  width: 385px;
  height: 60px;
  /* UI Properties */
  background: #0099b0 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 2px solid #0099b0;
  border-radius: 30px;
  opacity: 1;
}
.priceTag .btnprices a {
  text-align: left;

  font-size: 24px;
  font-family: SourceSansProRegular;
  font-weight: 600;
  letter-spacing: 0px;
  color: #ffffff;
  text-decoration: none;
  opacity: 1;
}
.priceTag .StTitle {
  width: 162px;
  height: 60px;
  /* UI Properties */
  background: #9dd162 0% 0% no-repeat padding-box;
  border-radius: 50px;
  opacity: 1;
  margin-top: 20px;
  margin-bottom: 10px;
  border: none;
}

.priceTag .StTitle a {
  text-align: left;

  
  font-size: 24px;
  font-family: SourceSansProRegular;
  font-weight: 600;
  letter-spacing: 0px;
  color: #ffffff;
  text-decoration: none;
  opacity: 1;
}

.priceTag .PtTitle {
  width: 140px;
  height: 60px;
  /* UI Properties */
  background: #ff99d0 0% 0% no-repeat padding-box;
  border-radius: 50px;
  opacity: 1;
  margin-top: 20px;
  margin-bottom: 10px;
  border: none;
}

.priceTag .PtTitle a {
  text-align: center;

  font-size: 24px;
  font-family: SourceSansProRegular;
  font-weight: 600;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  text-decoration: none;
}

/* slider section */

#sliderImg {
  width: 100%;
  height: 768px !important;
}

.sliderTitle {
  text-align: left;
  letter-spacing: 5.12px;
  color: #ce181e;
  font-size: 32px;
  font-family: SourceSansProBold;
  font-weight: bold;
  text-transform: uppercase;
  opacity: 1;
}
.carousel-caption {
  position: absolute;
  right: 24% !important;
  bottom: 16.25rem !important;
  left: 3% !important;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
  width: 100%;
}
.slidersubTitle {
  text-align: left;
  font-family:SourceSansProRegular;
  font-weight: normal;
  font-size: 20px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  width: 90%;
  line-height: 40px;
}

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #c32828 !important;
}
.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 4% !important;
  left: 80% !important;
  flex-direction: column !important;
  z-index: 2;
  display: flex;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 20px !important;
  height: 20px !important;
  border-radius: 50% !important;
  text-indent: 0 !important;
  background-color: #ffffff !important;
}

.icons {
  color: white;
  font-size: 16px;
  position: relative;
  margin-left: 9px;
}

.indicator-content {
  color: #ffffff;
  /* border-radius: 50%; */
  position: relative;
  bottom: 3px;
  font-size: 12px;
  font-weight: bold;
}

/* Styles for mobile devices */
@media only screen and (max-width: 767px) {
  /* Add your mobile-specific styles here */
  #sectionFive {
    width: 100%;
    height: 100%;
    opacity: 1;
    margin-top: 40px;
  }
  .App {
    width: 100%;
  }
  .carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 4% !important;
    left: 70% !important;
  }
  .navbar-right {
    display: none;
  }
  .navbar-brand {
    position: relative !important;
    left: 1% !important;
    top: 0px !important;
  }
  .navbar-collapse {
    position: absolute;
    background: #ffffff59;
    height: 215px;
    width: 290px;
    top: 100%;
  }
 
  .navbar-nav .nav-item {
    padding: 10px !important;
  }
  #sectionFour {
    width: 100%;
    /* margin-bottom: 31%; */
    height: 20%;
    padding: 17px;
    background-size: cover;
  }
 
  .select {
    text-align: justify;
  }

  .pos_content_para {
    margin-top: 16px !important;
    text-align: center;
  }
  #sectionThrees {
    margin-top: 100%;
    height: 100%;
  }
  .cardBody {
    width: 90%;
  }
  .cardText {
    width: 100%;
    margin-bottom: 20px;
  }
  .priceTag .PriceCheckbox li .borderSpan {
    width: 90%;
  }
  .priceTag .btnprice {
    width: 90%;
  }
  .priceTag .btnprices {
  
    width: 90%;
  }
  
  
  .navbar-nav {
    position: relative !important;
    bottom: 0 !important;
    top: 19px !important;
    right: 0 !important;
  }
  .bg_sec{

    height: 200px !important;
  }
 
    #footerLogo{
      margin-left: 0px !important;
    }
    .LocationText{
      position: relative;
      top: 0px !important;
      left: 0px !important;
    }
    #copyRight{
      margin-left: 0px !important ;
    }
    .navbar-toggler{
      background: #ffffff52 !important;
    }
    .pos_content_title {
      text-align: center;}
      .button_bg {
        /* display: flex; */
        width: 100%;}
}

/* Styles for tablet devices */
@media only screen and (min-width: 768px) and (max-width: 1023px){
  /* Add your tablet-specific styles here */
  #sectionThrees {
  
    height: 100%;
 
}
#sectionThree {

  height: 100%;
 
}
.bg_sec{

  height: 200px !important;
} .cardBody {
  width: 90%;
}
.cardText {
  width: 100%;
  margin-bottom: 20px;
}
.priceTag .PriceCheckbox li .borderSpan {
  width: 90%;
}
.priceTag .btnprice {
  width: 90%;
}
.priceTag .btnprices {

  width: 90%;
}
#sectionFive {
  width: 100%;
  height: 100%;
  opacity: 1;
  margin-top: 25%;
}
#sectionFour {
  width: 100%;
  /* margin-bottom: 31%; */
  height: 20%;
  padding: 17px;
  background-size: cover;
}
/* .InputTexts {
    width: 100%;
    right: 0;
    top: 7px;
} */

  #footerLogo{
    margin-left: 0px !important;
  }
  .LocationText{
    position: relative;
    top: 0px !important;
    left: 0px !important;
  }
  #copyRight{
    margin-left: 0px !important ;
  }
  .navbar-toggler{
    background: #ffffff52 !important;
  }
  .App {
    width: 100%;
  }
  .carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 4% !important;
    left: 70% !important;
  }
  .navbar-right {
    display: none;
  }
  .navbar-brand {
    position: relative !important;
    left: 1% !important;
    top: 0px !important;
  }
  .navbar-collapse {
    position: relative;
    background: #ffffff59;
    height: 215px;
  }
  .navbar-nav {
    position: relative !important;
    bottom: 0 !important;
    top: 19px !important;
    right: 0 !important;
  }
  .navbar-nav .nav-item {
    padding: 10px !important;
  }
  .pos_content_title {
    text-align: center;}
    
  .pos_content_para {
    margin-top: 16px !important;
    text-align: center;
  }
  .button_bg {
    /* display: flex; */
    width: 100%;}
}

