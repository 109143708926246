.bodycontent{
    padding: 40px;
    min-height: 568px;
}
.rbac_div{
    display: flex;
}
.rbac_head_submit {
    background: #2F3B7D 0% 0% no-repeat padding-box;
    border-radius: 6px;
    margin-left: 80%;
    width: 100px;
    height: 35px;
    opacity: 1;
    font-size:18px;
    font-family: RobotoMedium;
    text-align: center;
    letter-spacing: 0px;
    line-height: 22px;
    color: #FFFFFF;
}
.topic_title{
    /* width: 54px; */
    height: 28px;
    text-align: left;
    letter-spacing: 0.2px;
    line-height: 24px;
    color: #262626;
    opacity: 1;
    font-size: 20px;
    font-family: SourceSansProBold;
}

.rbac_table_serach{
    display: flex;
}
.rbac_table_serach input{
    width: 300px;
    height: 36px;
    border: 1px solid #04113420;
    border-radius: 6px; 
    font-family: SourceSansProRegular;
    text-align: left;
    font-size: 14px;
    line-height: 20px;
    opacity: 1;  
    letter-spacing: 0px;
    padding-left: 10px;
    color: #262626;
}
.rbac_table_serach_img{
    margin-left: -4%;
    width: 20px;
    height: 20px;
    margin-top: auto;
    margin-bottom: auto;
    color: #26262640;
}
.rbac_labelText{
    height: 17px;
    /* UI Properties */
    font-size:14px;
    font-family: RobotoMedium;
    text-align: left;
    letter-spacing: 0px;
    line-height: 17px;
    color: #121212;
    opacity: 1;
    display: block;
    margin-bottom: 5px;
}
/* .rbac_content{
     margin-left: 150px;
    margin-top: 30px;
    width: 650px; 
} */
.rbac_submit_bg {
    text-align: right;
    margin-top: 45px;
}
.rbac_labelText{
    height: 17px;
    /* UI Properties */
    font-size:14px;
    font-family: RobotoMedium;
    text-align: left;
    letter-spacing: 0px;
    line-height: 17px;
    color: #121212;
    opacity: 1;
    display: block;
    margin-bottom: 5px;
}
.rbac_InputText{
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 6px;
    width: 100%;
    height: 35px;
    border: #0099b0 solid 0.5px;
    padding-left: 10px;
    text-align: left;
    font-size: 14px;
    line-height: 31px;
    opacity: 1;
    font-family: RobotoRegular;
}
.rbac_Textarea {
  background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 6px;
    width: 100%;
    height: 100px;
    border: #0099b0 solid 0.5px;
    padding-left: 10px;
    text-align: left;
    font-size: 14px;
    line-height: 17px;
    opacity: 1;
    font-family: RobotoRegular;
}
.inp_grp{

    margin-top: 10px;
}
.rbac_submit_bg{
    text-align: right;
    margin-top: 40px;
}
/* .rbac_submit {
    background: #2F3B7D 0% 0% no-repeat padding-box;
    border-radius: 6px;
    width: 180px;
    height: 40px;
    opacity: 1;
    font-size:18px;
    font-family: RobotoMedium;
    text-align: center;
    letter-spacing: 0px;
    line-height: 22px;
    color: #FFFFFF;
} */
.rbac_td_action{
     
    margin-left: 5px;
    margin-right: 5px;
    color: #121212;
    opacity: .5;
}
.rbac_td_action:hover{
    color: #0099b0;
    opacity: 1;
}
.add_new{
    width: 114px;
height: 36px;
background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px #00000029;
border: 1px solid #99D6DF;
border-radius: 6px;
opacity: 1;
}
.add_new img {

    width: 24px;
height: 24px;
opacity: 1;
}

.add_new span{
    /* width: 56px;
height: 20px; */
text-align: left;
line-height: 20px;
font-family: SourceSansProRegular;
font-size: 14px;
letter-spacing: 0px;
color: #0099B0;
opacity: 1;
margin-left: 8px;
text-decoration: none;

}
.rbac_save_buttons {
        opacity: 1;
    font-size: 19px;
    vertical-align: text-bottom;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: -30px;
    color: #0099B0;
    position: relative;
}
.rbac_save_button {
    width: 87px;
height: 36px;
/* UI Properties */
background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px #00000029;
border: 1px solid #99D6DF;
border-radius: 6px;
opacity: 1;
line-height: 20px;
font-family: SourceSansProRegular;
font-size: 14px;
letter-spacing: 0px;
color: #0099B0;
opacity: 1;
margin-left: 8px;
text-decoration: none;
}
.ReviewLabel { 

    /* width: 83px; */
height: 20px;
/* UI Properties */
text-align: left;
line-height: 20px;
font-family: SourceSansProRegular;
font-size: 14px;
letter-spacing: 0px;
color: #262626;
opacity: 1;
font-weight: 600;

}
.ReviewLabels{

    /* width: 75px; */
height: 20px;
/* UI Properties */

text-align: right;
line-height: 20px;
font-family: SourceSansProBold;
font-size: 14px;
letter-spacing: 0px;
color: #262626;
opacity: 0.5;
display: flex;
}
.back_buttons{
    width: 82px;
height: 36px;
/* UI Properties */
background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px #00000029;
border: 1px solid #99D6DF;
border-radius: 6px;
opacity: 1;
}
.back_buttons .material-icons {
    font-size: 16px !important;
    vertical-align: middle !important;
    color: #99D6DF !important;
}
.back_buttons a{

    width: 24px;
height: 20px;

text-align: left;
font-weight: 600;
line-height: 20px;
font-family: SourceSansProRegular;
font-size: 14px;
letter-spacing: 0px;
color: #0099B0;
opacity: 1;
text-decoration: none;
}

.rbac_box_border{
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 6px;
    width: 100%;
    height: 100%;
    border: #0099b0 solid 0.5px;
    padding-left: 10px;
    opacity: 1;
}
.rbac_checkbox{

    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 5px;
    width: 18px;
    height: 18px;
    border: 1px solid #0099b0;
    opacity: 1;
    vertical-align: middle;
    margin-right: 5px;
}
input[id="acc_0"] {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 5px;
    width: 18px;
    height: 18px;
    border: 1px solid #0099b0;
    opacity: 1;
    vertical-align: middle;
    margin-right: 5px;
}
.rbac_btn_act{
    width: 80px;
height: 25px;
background: #14A44D 0% 0% no-repeat padding-box;
border: 1px solid #14A44D;
border-radius: 3px;
opacity: 1;
color: white;

}
.rbac_btn_inact{
    width: 80px;
height: 25px;
background: #DC4C64 0% 0% no-repeat padding-box;
border: 1px solid #DC4C64;
border-radius: 3px;
opacity: 1;
color: white;

}
.apbutton {
    background-color: green; 
    color: white; 
    padding: 5px 10px; 
    margin-right: 5px; 
    border: none;
    border-radius: 6px;
    cursor: pointer;
  }
  
  .clbutton {
    padding: 5px 10px; 
    border: none;
    background-color:white;
    cursor: pointer;
    margin-right: 10px;
    text-align: left;
    font-size: var(--fontSizeFourteen);
    color: red;
  }

  .filter-option{
    margin-left: calc(var(--bs-gutter-x)*0.5);
    margin-right: calc(var(--bs-gutter-x)*0.5);
    --bs-gutter-x:1.5rem;
    --bs-gutter-y:1;
    line-height: 25px;
  }
.div1{
    display: flex;
    flex-wrap: wrap;
    margin-left: calc(var(--bs-gutter-x)*0.5);
    margin-right: calc(var(--bs-gutter-x)*0.5);
    --bs-gutter-x:1.5rem;
    --bs-gutter-y:1;
}
.div1>*{
    margin-top: var(--bs-gutter-y);
    max-width: 100%;
    padding-left: calc(var(--bs-gutter-x)*.5);
    padding-right: calc(var(--bs-gutter-x)*.5);
}
.filter-popup {
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    padding: 10px;
    z-index: 99;
    width: 217px;
    /* Additional styles as needed */
  }